.app-header {
  position: fixed;
  background: var(--deg-white);
  &.active {
    background: var(--deg-white);
    .container {
      .section-top {
        .logo-container {
          .app-logo-secondary {
            opacity: 1;
          }
        }
      }
    }
  }
  &.scroll-down {
    background: var(--deg-white);
    .container {
      .section-top,
      .section-bottom {
        .item {
          color: var(--deg-pure-black);
        }
      }
      .section-top {
        .icon-hamburger-menu {
          line {
            stroke: var(--deg-pure-black);
          }
        }
      }
    }
  }
  @include screen-lg-max {
    padding: 25px 20px;
  }
  .container {
    flex-direction: unset;
    .section-top {
      .logo-container {
        display: flex;
        align-items: center;
        .app-logo {
          &.app-logo-primary {
            height: 85px; // height needs to be defined for secondary logo animation
            @include screen-lg-max {
              display: none;
            }
            .logo {
              width: 200px;
              height: auto;
            }
          }
          &.app-logo-secondary {
            width: 22px;
            @include screen-lg-max {
              display: block;
              opacity: 1;
            }
            .logo {
              width: 100%;
              height: auto;
            }
          }
          line {
            stroke: var(--deg-pure-black);
            transition: stroke 0.3s ease-in-out; 
          }
        }
      }
      .icon-hamburger-menu {
        @include screen-lg-max {
          display: flex;
        }
        &.mobile {
          line {
            stroke: var(--deg-pure-black);
          }
        }
      }
    }
    .section-bottom {
      .item {
        color: var(--deg-pure-black);
      }
    }
  }
}