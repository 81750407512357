.image-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: hidden;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: var(--app-wrapper-large);
    .image {
      position: relative;
      width: 100%;
      height: auto;
    }
  }
}