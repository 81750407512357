.app-tagline {
  position: fixed;
  bottom: 8rem;
  left: -2.5rem;
  color: var(--deg-pure-black);
  transition: color 0.5s;
  pointer-events: none;
  z-index: 99;
  @include screen-lg-max {
    display: none;
  }
  .tagline-text {
    font-family: var(--montserrat);
    font-size: 12px;
    font-weight: 600;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
  }
}

.app-tagline.light {
  color: var(--deg-white);
}