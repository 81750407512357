:root {
  // neutral colours
  --deg-pure-black: #000000;
  --deg-white: #FFFFFF;
  --deg-neutral-grey-1: #a4a3a6;
  --deg-neutral-grey-2: #333333;
  --deg-neutral-grey-3: #8c8c8c;
  --deg-dark-grey: #CCCCCC;
  --deg-mid-grey: #EFEFEF;

  // shadows
  --tile-drop-shadow: drop-shadow(0px 0px 80px rgba(14, 16, 38, 0.1));
}