.contact-form-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .contact-form-container {
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .heading {
      margin-bottom: 40px;
    }
  }
}