.benefits-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .benefits-container {
    display: flex;
    justify-content: center;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    flex-wrap: wrap;
    gap: 30px;
    .benefits-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex-basis: calc(33% - 18px);
      @include screen-xl-max {
        flex-basis: calc(50% - 15px);
      }
      @include screen-md-max {
        flex-basis: 100%;
      }
      .heading {
        margin: 14px 0;
        font-weight: 400;
      }
    }
  }
}