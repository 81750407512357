.page-detail {
  .app-page-modules {
    .block {
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.6s ease-out, opacity 0.6s ease-in-out;
    }
    .block.block-transition {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}