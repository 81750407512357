.partners-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .partners-container {
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .top {
      padding: 80px 100px 0;
      @include screen-xl-max {
        padding: 80px 80px 0;
      }
      @include screen-md-max {
        padding: 80px 34px 0;
      }
      .subheading {
        margin: 48px 0;
      }
      .tabs-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        .plus {
          .plus-vertical {
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
        @include screen-md-max {
          // height: 67px;
          max-height: 67px;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;
        }
        &.active {
          max-height: 500px;
          transition: max-height 0.3s ease-in-out;
          .plus {
            .plus-vertical {
              opacity: 0;
            }
          }
        }
      }
      .show-more-tabs {
        display: none;
        @include screen-md-max {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
          padding: 24px;
          .plus {
            width: 18px;
            height: 18px;
          }
        }
      }
      .tabs-container {
        display: flex;
        width: 100%;
        @include screen-md-max {
          flex-wrap: wrap;
        }
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 24px 0;
          flex: 1;
          text-align: center;
          font-weight: 600;
          cursor: pointer;
          transition: background 0.3s;
          @include screen-md-max {
            max-width: unset;
            flex: unset;
            flex-basis: 50%;
            font-weight: 400;
          }
        }
        .tab.active {
          background: var(--deg-white);
          font-weight: 600;
          @include screen-md-max {
            background: transparent;
          }
        }
      }
    }
    .bottom {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 48px 100px 60px;
      @include screen-xl-max {
        padding: 48px 80px 60px;
      }
      @include screen-md-max {
        padding: 50px 34px 30px;
      }
      .logo-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: calc(25% - 12px);
        max-height: 64px;
        height: 64px;
        @include screen-lg-max {
          flex-basis: calc(50% - 8px);
        }
        .logo {
          width: auto;
          height: 100%;
          @include screen-lg-max {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}