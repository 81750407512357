.media-text-row-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .media-text-row-container {
    display: flex;
    position: relative;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    @include screen-xxl-max {
      width: var(--app-wrapper-full);
    }
    @include screen-lg-max {
      flex-direction: column;
    }
    &.imageRightAligned {
      flex-direction: row-reverse;
      @include screen-lg-max {
        flex-direction: column;
      }
      .text-container {
        flex-direction: row-reverse;
        text-align: right;
        @include screen-lg-max {
          text-align: left;
          margin-top: 24px;
        }
      }
    }
    .image-media-container {
      position: relative;
      max-width: 50%;
      width: 50%;
      height: auto;
      @include screen-xl-max {
        max-width: 100%;
        width: 100%;
      }
    }
    .media-image,
    .media-video {
      position: relative;
      max-width: 100%;
      width: 100%;
      height: auto;
      object-fit: cover;
      filter: var(--tile-drop-shadow);
    }
    .text-container {
      display: flex;
      width: 50%;
      align-self: center;
      @include screen-xl-max {
        width: 100%;
      }
      @include screen-lg-max {
        margin-top: 24px;
      }
      .divider {
        min-width: 32px;
        width: 32px;
        height: 1px;
        background: var(--primary-colour);
        margin: 24px;
        @include screen-lg-max {
          display: none;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        .heading {
          font-weight: 600;
          color: var(--deg-pure-black);
          margin-bottom: 16px;
        }
        .body {
          font-weight: 400;
          color: #797979;
        }
      }
    }
  }
}