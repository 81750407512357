.news-opinions-block {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .news-opinions-container {
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .news-opinions-tile {
      display: flex;
      margin-bottom: 104px;
      text-decoration: none;
      &:first-child {
        margin-top: 0;
      }
      @include screen-xl-max {
        margin-bottom: 37px;
      }
      img {
        @media (hover: hover) {
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &:nth-child(2n) {
        .wrapper {
          flex-direction: row-reverse;
          @include screen-xl-max {
            flex-direction: column;
            align-items: flex-start;
          }
          .media-container {
            margin-right: 0;
            margin-left: 120px;
            filter: var(--tile-drop-shadow);
            @include screen-xl-max {
              margin-left: 0;
              margin-bottom: 24px;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        @include screen-xl-max {
          flex-direction: column;
        }
        .media-container {
          display: flex;
          align-items: center;
          position: relative;
          width: 484px;
          height: 290px;
          margin-right: 120px;
          overflow: hidden;
          @include screen-xl-max {
            width: 100%;
            height: unset;
            margin-right: 0;
            margin-bottom: 24px;
          }
          @include screen-sm-max {
            width: 100%;
          }
          img {
            position: relative;
            width: 100%;
            transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition-delay: 0ms;
            transition-delay: 0ms;
          }
        }
        .details-container {
          display: flex;
          max-width: 452px;
          width: 452px;
          flex-direction: column;
          justify-content: center;
          @include screen-xl-max {
            max-width: unset;
            width: 100%;
          }
          .tag.small {
            text-decoration: underline;
            text-underline-offset: 3px;
            margin-bottom: 5px;
            color: var(--deg-pure-black);
          }
          .detail-heading {
            color: var(--deg-pure-black);
            font-family: var(--montserrat);
          }
          .date-author {
            display: flex;
            margin-top: 16px;
            font-size: 16px;
            line-height: 24px;
            color: var(--deg-neutral-grey-1);
            font-family: var(--montserrat);
          }
        }
      }
    }
  }
}