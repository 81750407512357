.form-download-block {
  position: relative;
  width: var(--app-container-max);
  min-height: 537px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .form-download-container {
    position: relative;
    display: flex;
    width: var(--app-wrapper-medium);
    height: 100%;
    margin: 0 auto;
    color: var(--deg-white);
    padding: 60px;
    gap: 50px;
    @include screen-md-max {
      flex-direction: column;
      padding: 30px;
      gap: 30px;
    }
    .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
    .form-download-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }
    .form-download-section.left {
      display: flex;
      align-items: center;
      .text {
        font-weight: 600;
        color: var(--deg-pure-black);
      }
    }
    .form-download-section.right {
      form {
        display: flex;
        flex-direction: column;
        input {
          font-size: 20px;
          border: 0;
          background: transparent;
          color: var(--deg-white);
          border-bottom: 1px solid var(--deg-white);
          padding: 8px;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        input:focus{
          outline: none;
        }
        ::placeholder {
          color: var(--deg-white);
          opacity: 1;
        }
        :-ms-input-placeholder {
          color: var(--deg-white);
        }
        ::-ms-input-placeholder {
          color: var(--deg-white);
        }
      }
      button {
        align-self: flex-start;
        color: var(--deg-white);
        padding: 14px 36px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }
}