.career-enquiries-block {
  .career-enquiries-container {
    .partial-button {
      background: var(--primary-colour);
      .text-container {
        .text {
          color: var(--button-text-colour);
        }
        .arrow {
          line,
          polyline {
            stroke: var(--button-text-colour);
          }
        }
      }
    }
  }
}