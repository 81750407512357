.expertise-creative-carousel-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .expertise-creative-carousel-container {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    @include screen-md-max {
      width: 100%;
    }
    .process-slider-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 350px;
      overflow: hidden;
      z-index: 20;
      @include screen-sm-max {
        width: 350px;
      }
      .splide {
        position: relative;
        width: 100%;
        .splide__arrows {
          position: absolute;
          height: 45px;
          display: flex !important;
          top: 0;
          right: 0;
          z-index: 5;
          .splide__arrow {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45px;
            height: 45px;
            margin: 0;
            background: none;
            top: unset;
            transform: unset;
            border-radius: unset;
            cursor: pointer;
            svg {
              width: 26px;
              height: 13px;
            }
          }
          .splide__arrow--prev {
            left: 0;
          }
          .splide__arrow--next {
            right: 0;
          }
        }
        .splide__track {
          position: relative;
        }
        .splide__slide {
          position: relative;
          .slide-heading {
            position: relative;
          }
          .slide-divider {
            position: relative;
            width: 100%;
            height: 1px;
            background: var(--deg-pure-black);
            margin: 20px 0 24px;
          }
          .slide-description {
            position: relative;
            font-weight: 400;
          }
        }
      }
    }
    .process-graphic-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 700px;
      height: 700px;
      @include screen-md-max {
        width: 100%;
        height: unset;
        opacity: 0.3;
      }
      .process-graphic {
        position: relative;
      }
    }
  }
}