.icon-text-list-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .icon-text-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .block {
      display: flex;
      flex-basis: calc(50% - 15px);
      @include screen-lg-max {
        flex-basis: 100%;
      }
      .icon {
        min-width: 20px;
        width: 20px;
        min-height: 20px;
        height: 20px;
        margin-top: 5px;
      }
      .details-container {
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        .heading {
          font-weight: 600;
          margin-bottom: 5px;
        }
      }
    }
  }
}