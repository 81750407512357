.overlapping-double-media-text-block {
  .overlapping-double-media-text-container {
    .text-container {
      .details {
        .heading {
          color: var(--deg-pure-black);
        }
        .body {
          color: var(--deg-neutral-grey-2);
        }
      }
    }
  }
}