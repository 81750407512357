.horizontal-icon-tile-block {
  .horizontal-icon-tile-container {
    .desktop-tiles {
      .tile {
        .bottom {
          .bottom-wrapper {
            background: transparent;
            .icon {
              width: 82px;
            }
            .body {
              color: var(--deg-pure-black);
            }
          }
        }
      }
    }
    .tile {
      .top {
        .heading {
          color: var(--deg-pure-black);
        }
      }
      .bottom {
        .bottom-wrapper {
          background: transparent;
          .icon {
            width: 82px;
          }
          .body {
            color: var(--deg-pure-black);
          }
        }
      }
    }
  }
}