.listing-table-block {
  .listing-table-container {
    .heading {
      color: var(--deg-pure-black);
    }
    .subheading {
      color: var(--deg-pure-black);
    }
    .table-container {
      .item {
        color: var(--deg-pure-black);
        border-bottom: 1px solid var(--primary-colour);
      }
    }
    .view-more {
      .wrapper {
        .text {
          color: var(--deg-pure-black);
        }
        .arrow-down {
          path {
            stroke: var(--deg-pure-black);
            opacity: 0.25;
          }
        }
      }
    }
  }
}