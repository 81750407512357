.three-column-block {
  .three-column-container {
    .section {
      .section-rich-text {
        > * {
          color: var(--deg-pure-black);
        }
      }
    }
  }
}