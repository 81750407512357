.expertise-data-graph-1-block {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .expertise-data-graph-1-container {
    position: relative;
    width: $screen-lg-max;
    margin: 0 auto;
    @include screen-lg-max {
      width: 90%;
    }
    .data-graph-1 {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 250px;
      @include screen-lg-max {
        padding-bottom: 350px;
      }
      @include screen-lg-max {
        padding-bottom: 0;
      }
    }
    .text-blocks-container {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 100%;
      bottom: 0;
      left: 0;
      @include screen-lg-max {
        position: relative;
        flex-direction: column;
        margin-top: 50px;
      }
      .text-block {
        display: flex;
        flex-direction: column;
        max-width: 270px;
        width: 270px;
        margin-top: 100px;
        padding: 0 30px 0 0;
        &:last-child {
          padding-right: 0;
        }
        &:first-child,
        &:last-child {
          margin-top: -50px;
        }
        .heading {
          margin-bottom: 19px;
        }
        @include screen-lg-max {
          margin-top: 0;
          max-width: 100%;
          width: 100%;
          margin-bottom: 25px;
          &:first-child,
          &:last-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}