.results-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .results-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    @include screen-xxl-max {
      width: var(--app-wrapper-full);
    }
    .table-container {
      border: 1px solid var(--deg-mid-grey);
      margin: 48px 0;
      .row {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--deg-mid-grey);
        padding: 30px 100px;
        @include screen-xl-max {
          flex-direction: column;
          padding: 35px 30px;
        }
        &:last-child {
          border-bottom: 0;
        }
        .left {
          display: flex;
          flex: 1;
          padding-right: 48px;
          @include screen-xl-max {
            flex-basis: 100%;
          }
          .row-title {
            padding-right: 48px;
            @include screen-xl-max {
              margin-bottom: 15px;
              padding-right: 0;
              width: 100%;
            }
          }
          .row-divider {
            position: relative;
            width: 72px;
            min-width: 72px;
            height: 1px;
            margin-top: 24px;
            @include screen-xl-max {
              display: none;
            }
          }
        }
        .right {
          display: flex;
          flex-basis: 50%;
          @include screen-xl-max {
            flex-basis: 100%;
            padding: 0;
          }
          .row-body {
            display: flex;
            flex: 1;
            position: relative;
            color: var(--deg-mid-grey);
            font-weight: 400;
          }
        }
      }
    }
  }
}