.home-intro-block {
  &.block {
    margin-top: 0;
  }
  .background-image-container {
    .background-image {
      width: 420px;
      height: 95%;
      @include screen-md-max {
        width: 290px;
      }
    }
  }
  .home-intro-container {
    .heading-container {
      .heading {
        color: var(--deg-pure-black);
        font-weight: 600;
      }
    }
    .body {
      > * {
        color: var(--deg-pure-black);
      }
    }
  }
}