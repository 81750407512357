.jobs-listing-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .jobs-listing-container {
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .section {
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .heading {
      margin-bottom: 48px;
      text-align: center;
    }
    .table.table-identifier,
    .table.table-other {
      .row {
        border-bottom: 1px solid var(--deg-mid-grey);
        &:last-child {
          border-bottom: 0;
        }
      }
    }
    .table {
      border: 1px solid var(--deg-mid-grey);
      margin-bottom: 60px;
      &:last-child {
        margin-bottom: 0;
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 30px 50px;
        gap: 20px;
        color: var(--deg-pure-black);
        text-decoration: none;
        @include screen-xl-max {
          align-items: center;
          padding: 30px 50px;
        }
        @include screen-md-max {
          padding: 30px;
        }
        @media (hover: hover) {
          &:hover {
            cursor: pointer;
            .arrow-container {
              .icon-arrow-right {
                transform: translateX(0px);
                opacity: 1;
              }
            }
          }
        }
        .row-section {
          display: flex;
          align-items: center;
          flex: 3;
          @include screen-md-max {
            flex-direction: column;
            align-items: flex-start;
          }
        }
        .row-item,
        .row-item-other,
        .arrow-container {
          flex: 1;
        }
        .row-item.role,
        .row-item-other.role {
          flex: 1.5;
          @include screen-sm-max {
            h4 {
              max-width: 150px;
            }
          }
        }
        .arrow-container {
          display: flex;
          justify-content: flex-end;
          @include screen-md-max {
            flex: unset;
            margin-left: 1rem;
          }
          .icon-arrow-right {
            transform: translateX(-15px);
            opacity: 0;
            width: 25px;
            transition: all 0.3s;
            @include screen-md-max {
              transform: translateX(0px);
              opacity: 1;
            }
          }
        }
        .role-description {
          font-weight: 400;
        }
        .role-brand {
          text-align: right;
          font-weight: 400;
          color: var(--deg-neutral-grey-2);
          @include screen-md-max {
            margin-top: 16px;
          }
        }
      }
    }
    .more-info {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: 120px;
      @include screen-md-max {
        margin-top: 60px;
      }
      .cta-container {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--deg-pure-black);
        text-decoration: none;
        h5 {
          font-weight: 400;
        }
        .icon-arrow-right {
          display: inline;
          width: 20px;
          height: 15px;
          margin-left: 8px;
          transition: transform 0.3s;
        }
      }
    }
  }
}