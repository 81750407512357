.awards-block {
  .awards-block-container {
    .award {
      .image-wrapper {
        background: var(--deg-white);
      }
      .award-details {
        .heading {
          color: var(--deg-pure-black);
        }
        .subheading {
          color: var(--deg-pure-black);
        }
      }
    }
  }
}