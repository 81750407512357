.contact-multi-individual-block {
  .contact-multi-individual-container {
    .contact-multi-individual-item {
      .details-container {
        .details-wrapper {
          .email,
          .mobile {
            color: var(--primary-colour);
          }
          span.abbreviation {
            color: var(--deg-pure-black);
          }
        }
      }
    }
  }
}