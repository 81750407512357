.benefits-block {
  .benefits-container {
    .benefits-section {
      .heading {
        color: var(--deg-pure-black);
      }
      .subheading {
        color: var(--deg-neutral-grey-2);
      }
      .icon-container {
        .icon {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}