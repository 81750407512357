.subscribe-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .subscribe-container {
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .heading {
      font-weight: 400;
      margin-bottom: 24px;
    }
    .form-container {
      display: flex;
    }
  }
}