.app-share {
  position: fixed;
  right: 1.7rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background: var(--deg-white);
  @include screen-md-max {
    display: none;
  }
  .app-share-container {
    position: relative;
    display: flex;
    width: 36px;
    flex-direction: column;
    > * {
      margin-top: -1px;
    }
    .social-container {
      display: flex;
      width: 100%;
      flex-direction: column;
      > * {
        margin-top: -1px;
      }
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 36px;
        border: 1px solid var(--deg-neutral-grey-1);
        cursor: pointer;
        background: var(--deg-white);
        @media (hover: hover) {
          &:hover {
            border: 1px solid var(--deg-pure-black);
            z-index: 1;
            .icon path {
              fill: var(--deg-pure-black);                    
            }
          }
        }
        .icon {
          width: 30px;
          height: 30px;
        }
      }
    }
    .text-container {
      position: relative;
      width: 100%;
      height: 110px;
      border: 1px solid var(--deg-neutral-grey-1);
      background: var(--deg-white);
      .text {
        position: absolute;
        top: 50%;
        left: 50%;
        white-space: nowrap;
        font-size: 12px;
        color: var(--deg-neutral-grey-1);
        font-weight: 600;
        transform: translate(-50%, -50%) rotate(-90deg);
        transition: color 0.5s;
      }
    }
  }
}

.app-share.light {
  .text-container .text {
    color: var(--deg-white);
  }
  .icon-container .icon {
    path {
      fill: var(--deg-white);
    }
  }
}