.block-checklist {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .intro-text-container {
      .intro-text {
        > * {
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .checklist-container {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-between;
      width: 100%;
      .row-content {
        display: flex;
        flex-basis: calc(50% - 15px);
        @include screen-lg-max {
          flex-basis: 100%;
        }
        .check {
          min-width: 20px;
          width: 20px;
          min-height: 20px;
          height: 20px;
          margin-top: 5px;
        }
        .details-container {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          .heading {
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}