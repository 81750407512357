.four-column-block {
  display: flex;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .four-column-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    overflow: hidden;
    .section {
      display: flex;
      width: calc(25% - 18px);
      flex-direction: column;
      text-decoration: none;
      @include screen-lg-max {
        flex-basis: calc(50% - 20px);
      }
      @include screen-md-max {
        flex-basis: 100%;
      }
      a {
        text-decoration: none;
        color: var(--deg-pure-black);
        transition: color 0.3s ease-in-out;
        @media (hover: hover) {
          &:hover {
            .section-image {
              transform: scale(1.1);
              opacity: 0.5;
            }
            .section-text {
              > * {
                color: var(--link-hover-colour);
              }
            }
          }
        }
      }
      .section-image-wrapper {
        position: relative;
        display: flex;
        overflow: hidden;
        margin-bottom: 20px;
        .section-image {
          width: 100%;
          transform: scale(1);
          transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transition-delay: 0ms;
          transition-delay: 0ms;
        }
      }
      .section-text {
        > * {
          margin-bottom: 8px;
          transition: color 0.3s ease-in-out;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}