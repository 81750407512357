.app-error-404 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
  .container {
    width: var(--app-wrapper-medium);
    margin-left: auto;
    margin-right: auto;
    @include screen-lg-max {
      width: 90%;
    }
    .icon-404 {
      .letter {
        stroke-width: 0.2px;
        @include screen-md-max {
          stroke-width: 0.5px;
        }
      }
      path {
        animation-duration: 5s;
        animation-name: animateDash;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: ease-in-out;
      }
    }
    .text-container {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 60px;
      @include screen-md-max {
        max-width: 80%;
        margin: 60px auto;
      }
    }
  }
}

@keyframes animateDash {
  from {
    stroke-dashoffset: 502.7825622558594px;
    stroke-dasharray: 502.7825622558594 502.7825622558594;
  }

  to {
    stroke-dashoffset: 0px;
    stroke-dasharray: 502.7825622558594 502.7825622558594;
  }
}