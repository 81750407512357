.partial-button {
  position: relative;
  display: flex;
  background: var(--deg-pure-black);
  padding: 20px 40px;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  .text-container {
    position: relative;
    .text {
      font-weight: 600;
      color: var(--deg-white);
      transition: transform 0.2s;
      will-change: transform;
    }
    .arrow {
      position: absolute;
      right: 0;
      opacity: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      transition: all 0.2s;
    }
  }
  @media (hover: hover) {
    &:hover {
      .text {
        transform: translateX(-15px);
      }
      .arrow {
        right: -24px;
        opacity: 1;
      }
    }
  }
}