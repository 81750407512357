.text-grid-block {
  display: flex;
  overflow: hidden;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .text-grid-container {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .tile {
      position: relative;
      display: flex;
      flex-basis: calc(33.333% - 40px);
      flex-direction: column;
      text-decoration: none;
      @include screen-xl-max {
        flex-basis: calc(50% - 40px);
      }
      @include screen-md-max {
        flex-basis: 100%;
      }
      &.link {
        cursor: pointer;
        .tile-top {
          .heading {
            color: var(--primary-colour);
          }
        }
      }
      .tile-top {
        margin-bottom: 16px;
        .heading {
          display: inline;
          color: var(--deg-pure-black);
        }
        .icon-arrow-right {
          display: inline;
          width: 20px;
          height: 15px;
          margin-left: 8px;
          transition: transform 0.3s;
        }
      }
      .tile-bottom {
        font-family: var(--montserrat);
        color: var(--deg-neutral-grey-3);
        font-size: 18px;
        line-height: 30px;
        @include screen-md-max {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    @media (hover: hover) {
      .tile:hover {
        .icon-arrow-right {
          transform: translateX(10px);
        }
      }
    }
  }
}