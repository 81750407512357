.block.video-hero-block {
  .video-hero-container {
    .heading-overlay-desktop {
      .heading-overlay-desktop-container {
        .heading-overlay-text {
          text-transform: none;
        }
      }
    }
  }
}