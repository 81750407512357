.app-back-to-top {
  position: fixed;
  bottom: 10rem;
  right: -1rem;
  transform: rotate(-90deg);
  color: var(--deg-neutral-grey-1);
  transition: color 0.5s;
  transform-origin: 50% 50%;
  will-change: transform;
  cursor: pointer;
  z-index: 99;
  @include screen-lg-max {
    display: none;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .text {
      font-family: var(--montserrat);
      font-size: 12px;
    }
    .arrow {
      width: 27px;
      height: 14px;
      margin: 0 1rem;
      transition: transform 0.2s;
      line,
      polyline {
        transition: stroke 0.5s;
        stroke: var(--deg-neutral-grey-1);
        transition: stroke 0.2s;
      }
    }
  }
  @media (hover: hover) {
    &:hover {
      color: var(--deg-pure-black);
      .arrow {
        transform: translateX(10px);
        line,
        polyline {
          stroke: var(--deg-pure-black);
        }
      }
    }
  }
}

.app-back-to-top.light {
  color: var(--deg-white);
  .arrow {
    line,
    polyline {
      stroke: var(--deg-white);
    }
  }
}