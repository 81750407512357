.page-intro-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 720px;
  overflow: hidden;
  &.block {
    margin-top: 0;
  }
  @include screen-md-max {
    height: 95vh;
  }
  &.animate1 {
    .background-image {
      transform: scale(1);
    }
    .page-intro-container {
      .details-container {
        .heading {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
  &.animate1.animate2 {
    .page-intro-container {
      .details-container {
        .subheading {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1.05);
    transition: transform 1.5s ease-in-out;
    transform-origin: 50% 50%;
    object-fit: cover;
    z-index: 0;
  }
  .page-intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: var(--app-container-max);
    margin-left: auto;
    margin-right: auto;
    @include screen-md-max {
      margin-top: -50px;
    }
    .details-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      z-index: 5;
      .icon {
        max-width: 60.408px;
        width: 60.408px;
        height: auto;
        margin-bottom: 16px;
      }
      .icon-label {
        color: var(--deg-white);
        text-decoration: underline;
        font-weight: 400;
        text-underline-offset: 8px;
      }
      .heading,
      .subheading {
        color: var(--deg-white);
      }
      .heading {
        margin: 35px 0;
        opacity: 0;
        transform: translateY(20px);
        transition: transform 0.8s ease-in-out, opacity 1s ease-in-out;
      }
      .subheading {
        opacity: 0;
        font-weight: 400;
        transform: translateY(20px);
        transition: transform 0.8s ease-in-out, opacity 1s ease-in-out;
      }
    }
  }
}