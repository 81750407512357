.page-intro-text-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  &.enter {
    .container {
      .heading-container:after {
        width: 100%;
        left: 0;
      }
    }
  }
  &.enter.exit {
    .container {
      .heading {
        opacity: 1;
      }
      .heading-container:after {
        width: 0;
        left: unset;
        right: 0;
      }
      .subheading {
        opacity: 1;
        transform: translate(0px);
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: var(--deg-pure-black);
      text-underline-offset: 3px;
      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
    h4 {
      font-weight: 400;
    }
    h5 {
      font-weight: 400;
    }
    .heading-container {
      position: relative;
      .heading {
        opacity: 0;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 102%;
        top: 0;
        right: 0;
        transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition-delay: 0ms;
      }
    }
    .subheading {
      opacity: 0;
      transform: translateY(30px);
      transition: transform 0.8s ease-out, opacity 0.8s ease-in-out;
    }
  }
}