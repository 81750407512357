.career-enquiries-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .career-enquiries-container {
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .body {
      margin: 48px 0;
      a {
        text-decoration: none;
        @media (hover: hover) {
          &:hover {
            text-decoration: underline;
            text-underline-offset: 4px;
          }
        }
      }
    }
    .partial-button {
      align-self: center;
      background: var(--primary-colour);
      .text-container {
        .text {
          color: var(--button-text-colour);
        }
        .arrow {
          line,
          polyline {
            stroke: var(--button-text-colour);
          }
        }
      }
    }
  }
}