.history-block {
  display: flex;
  position: relative;
  width: 100%;
  .history-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    z-index: 1;
    .text-wrapper {
      width: var(--app-container-max);
      margin-left: auto;
      margin-right: auto;
      .text-container {
        > * {
          margin-top: 16px;
          &:first-child {
            margin-top: 0;
          }
        }
        max-width: 60%;
        @include screen-xxxl-max {
          max-width: 520px;
        }
        .body {
          font-weight: 400;
          margin-top: 40px;
          p {
            margin-bottom: 18px;
            &:last-child {
              margin: 0;
            }
          }
        }
      }
    }
  }
  img {
    position: absolute;
    top: 50%;
    right: 0;
    width: 650px;
    transform: translateY(-50%);
    @include screen-md-max {
      width: 450px;
    }
    @include screen-sm-max {
      width: 80%;
    }
  }
}