.image-text-grid-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .heading {
    margin-bottom: 75px;
    @include screen-md-max {
      margin-bottom: 70px;
    }
  }
  .image-text-grid-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .image-text-item {
      display: flex;
      align-items: center;
      @include screen-md-max {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 60px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .image-container {
        position: relative;
        width: 50%;
        aspect-ratio: 1 / 1;
        @include screen-md-max {
          width: 100%;
        }
        @include screen-sm-max {
          width: 100%;
        }
        .divider {
          position: absolute;
          content: '';
          width: 80px;
          height: 1px;
          right: -40px;
          top: 50%;
          transform: translateY(-50%);
          background: var(--primary-colour);
          z-index: 5;
          @include screen-md-max {
            display: none;
          }
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
      .details-container {
        position: relative;
        width: 50%;
        padding: 0 60px;
        @include screen-md-max {
          width: 100%;
          padding: 0;
          margin-top: 32px;
          padding-left: 0;
        }
        p.small {
          margin-top: 8px;
        }
        .divider {
          display: none;
          position: absolute;
          top: 50%;
          left: 0;
          width: 24px;
          height: 1px;
          transform: translateY(-50%);
          z-index: 5;
          @include screen-md-max {
            display: block;
          }
        }
      }
    }
    .image-text-item:nth-child(2n+1) {
      flex-direction: row-reverse;
      @include screen-md-max {
        flex-direction: column;
        align-items: flex-start;
      }
      .image-container {
        .divider {
          position: absolute;
          width: 80px;
          height: 1px;
          left: -40px;
          top: 50%;
          background: var(--primary-colour);
          transform: translateY(-50%);
          z-index: 5;
          @include screen-md-max {
            display: none;
          }
        }
      }
    }
  }
}