.team-block {
  .team-container {
    .text-container {
      .inner {
        .text-top,
        .text-bottom {
          color: var(--deg-pure-black);
        }
      }
    }
  }
}