.media-text-column-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  &.leftAlignText {
    .media-text-column-container {
      .text-container {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        text-align: left;
        max-width: 720px;
        width: 720px;
        margin-left: 84px;
        @include screen-xl-max {
          max-width: 100%;
          width: 100%;
          margin-left: 0;
        }
      }
      .divider {
        align-self: flex-start;
      }
    }
  }
  .media-text-column-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .image-media-container {
      position: relative;
      width: 100%;
      height: 100%;
    }
    .media-image,
    .media-video {
      position: relative;
      width: 100%;
      height: auto;
      filter: var(--tile-drop-shadow);
      object-fit: cover;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      align-self: flex-end;
      @include screen-xl-max {
        width: 100%;
        align-self: flex-start;
      }
      .divider {
        position: relative;
        width: 1px;
        height: 32px;
        background: var(--primary-colour);
        margin: 24px 0;
        @include screen-xl-max {
          display: none;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        @include screen-xl-max {
          margin-top: 24px;
        }
        .heading {
          font-weight: 600;
          color: var(--deg-pure-black);
          margin-bottom: 16px;
        }
        .body {
          font-weight: 400;
          color: #797979;
        }
      }
    }
  }
}