.logo-text-highlight-block {
  .logo-text-highlight-container {
    border: 1px solid var(--deg-pure-black);
    .section-left {
      background: var(--deg-pure-black);
    }
    .section-right {
      .body {
        color: var(--deg-pure-black);
      }
    }
  }
}