.jobs-listing-block {
  .jobs-listing-container {
    .section.top,
    .section.bottom {
      .heading {
        color: var(--deg-pure-black);
      }
      .table {
        .row {
          color: var(--deg-pure-black);
          .arrow-container {
            .icon-arrow-right {
              path {
                stroke: var(--deg-pure-black);
              }
            }
          }
        }
      }
    }
    .more-info {
      .heading {
        color: var(--deg-pure-black);
      }
      .cta-container {
        color: var(--deg-pure-black);
        .icon-arrow-right {
          path {
            stroke: var(--deg-pure-black);
          }
        }
      }
    }
  }
}