.locations-block {
  .container {
    .location-tiles-container {
      .tile {
        .details-container {
          color: var(--deg-pure-black);
          .details {
            .detail-block {
              .icon {
                svg {
                  path {
                    stroke: var(--deg-pure-black);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}