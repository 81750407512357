.expertise-details-block {
  position: relative;
  .expertise-details-container {
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
    .expertise-details-modules {
      .block {
        margin-bottom: 120px;
        @include screen-md-max {
          margin-bottom: 60px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .expertise-anchors {
      width: 100%;
      .expertise-anchors-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: $screen-md-max;
        @include screen-md-max {
          width: 90%;
        }
        .icon-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @media (hover: hover) {
            &:hover {
              .icon {
                svg {
                  path {
                    stroke: var(--primary-colour);
                  }
                }
              }
              .label {
                color: var(--primary-colour);
              }
            }
          }
          .icon {
            position: relative;
            svg {
              width: 100%;
              height: auto;
              path {
                transition: stroke 0.3s ease-in-out;
              }
            }
          }
          .label {
            display: flex;
            justify-content: center;
            position: relative;
            margin-top: 16px;
            transition: color 0.3s ease-in-out;
            @include screen-sm-max {
              max-width: 85px;
            }
          }
        }
        .icon-container.left .icon,
        .icon-container.right .icon {
          width: 80px;
          height: auto;
          @include screen-md-max {
            width: 70px;
            height: auto;
          }
        }
        .icon-container.left,
        .icon-container.right {
          .label {
            font-weight: 400;
            color: var(--deg-neutral-grey-1);
            transition: color 0.3s ease-in-out;
          }
        }
        .icon-container.middle .icon {
          width: 140px;
          height: auto;
          @include screen-md-max {
            max-width: 104px;
            width: 104px;
            height: auto;
          }
          svg {
            path {
              stroke: var(--primary-colour);
            }
          }
        }
      }
    }
  }
}