.news-page-intro-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  > * {
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .news-page-intro-container {
    position: relative;
    width: 100%;
    .intro-container {
      display: flex;
      flex-direction: column;
      width: var(--app-wrapper-medium);
      margin: 0 auto;
      > * {
        margin-bottom: 48px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .small-title {
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 3px;
        font-family: var(--montserrat);
      }
      .large-title {
        font-weight: 600;
      }
      .date-author-container {
        .date-author {
          color: var(--deg-neutral-grey-1);
          font-weight: 400;
        }
      }
    }
    .hero-image-container {
      position: relative;
      width: var(--app-wrapper-large);
      margin: 48px auto;
      img {
        width: 100%;
      }
    }
  }
}