.case-studies-block {
  position: relative;
  display: flex;
  flex-direction: column;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .case-studies-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .tiles-container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: var(--app-wrapper-large);
      margin: 0 auto;
      .case-studies-tiles-tile {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: calc(50% - 60px);
        aspect-ratio: 2 / 2.1;
        margin-top: -200px;
        margin-bottom: -40px;
        filter: var(--tile-drop-shadow);
        color: var(--deg-white);
        cursor: pointer;
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 1s ease-in-out, transform 1s ease-in-out;
        overflow: hidden;
        @include screen-xxl-max {
          width: calc(50% - 25px);
          margin-top: -180px;
        }
        @include screen-xl-max {
          margin-top: -155px;
        }
        @include screen-lg-max {
          width: 100%;
          margin-top: 0;
          margin-bottom: 40px;
        }
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(2n+0) {
          align-self: flex-end;
        }
        cursor: pointer;
        @media (hover: hover) {
          &:hover {
            .tile-background {
              transform: scale(1.1);
            }
            .tile-container {
              &:after {
                background-color: rgba(0, 0, 0, 0.4);
              }
            }
          }
        }
        .tile-container {
          position: relative;
          width: 100%;
          height: 115%;
          padding: 40px;
          overflow: hidden;
          transform: translateY(0px);
          transition: transform 1s ease-in-out;
          &:after {
            -webkit-transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition-delay: 0ms;
            transition-delay: 0ms;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(0,0,0,0.2);
          }
          .tile-background {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: all 1000ms cubic-bezier(0.19, 1, 0.22, 1);
            -webkit-transition-delay: 0ms;
            transition-delay: 0ms;
          }
        }
        .tile-details {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          color: var(--deg-white);
          .tag.small {
            position: absolute;
            top: 40px;
            left: 34px;
            text-decoration: underline;
            font-weight: 400;
            text-decoration: underline;
            text-underline-offset: 5px;
          }
          .body {
            position: absolute;
            bottom: 40px;
            left: 34px;
            width: 80%;
          }
        }
      }
      .case-studies-tiles-tile.tile-transition {
        opacity: 1;
        transform: translateY(0px);
        .tile-container {
          transform: translateY(-50px);
        }
      }
    }
  }
}