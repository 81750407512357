.vertical-icon-tile-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .vertical-icon-tile-container {
    display: flex;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    flex-direction: column;
    gap: 64px;
    .tile {
      display: flex;
      width: 100%;
      min-height: 346px;
      filter: var(--tile-drop-shadow);
      @include screen-lg-max {
        flex-direction: column;
        min-height: unset;
        height: unset;
      }
      .left,
      .bottom {
        padding: 30px;
      }
      .left {
        display: flex;
        position: relative;
        min-width: 236px;
        width: 236px;
        @include screen-lg-max {
          justify-content: flex-start;
          min-width: 100%;
          width: 100%;
        }
        .left-background-colour {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }
        .heading-container {
            position: absolute;
            top: 50%;
            left: 118px;
            transform: translateY(-50%);
            display: flex;
            flex-direction: column;
            max-width: 150px;
            width: 150px;
            z-index: 2;
            @include screen-lg-max {
              position: relative;
              text-align: center;
              max-width: 100%;
              width: 100%;
              top: unset;
              left: unset;
              transform: unset;
            }
            .heading-index {
              text-decoration: underline;
              text-underline-offset: 8px;
              margin-bottom: 16px;
              @include screen-lg-max {
                display: none;
              }
            }
            .heading-desktop {
              @include screen-lg-max {
                max-width: 250px;
                text-align: center;
                align-self: center;
              }
              @include screen-md-max {
                max-width: 150px;
              }
            }
            .icon-container {
              display: none;
              @include screen-lg-max {
                display: flex;
                justify-content: center;
                margin-bottom: 24px;
                .icon {
                  max-width: 130px;
                  width: 130px;
                }
              }
            }
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        padding: 80px 160px;
        background: var(--deg-white);
        @include screen-xl-max {
          padding: 80px 120px;
        }
        @include screen-lg-max {
          padding: 40px;
        }
        @include screen-lg-max {
          flex-direction: column-reverse;
          flex-grow: 0;
        }
        .right-body-container {
          display: flex;
          .body {
            font-weight: 400;
            max-width: 305px;
            width: 305px;
            @include screen-lg-max {
              max-width: 100%;
              width: 100%;
            }
          }
        }
        .right-icon-container {
          display: flex;
          .icon {
            width: 120px;
          }
          &.desktop {
            @include screen-lg-max {
              display: none;
            }
          }
        }
      }
    }
  }
}