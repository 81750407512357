.logo-text-highlight-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  &.textLeftAligned {
    .logo-text-highlight-container {
      flex-direction: row-reverse;
      @include screen-lg-max {
        flex-direction: column;
      }
    }
  }
  .logo-text-highlight-container {
    display: flex;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    @include screen-lg-max {
      flex-direction: column;
    }
    .section-left {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 60px;
      @include screen-lg-max {
        justify-content: center;
      }
      .logo {
        max-width: 180px;
        width: 180px;
      }
    }
    .section-right {
      display: flex;
      align-items: center;
      flex: 1;
      background: var(--deg-white);
      padding: 40px 60px;
      .body {
        font-weight: 400;
      }
    }
  }
}