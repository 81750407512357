.page-intro-text-block {
  .container {
    .heading-container {
      &:after {
        background: var(--primary-colour);
      }
      .heading {
        color: var(--deg-pure-black);
      }
      .subheading {
        color: var(--deg-pure-black);
      }
    }
  }
}