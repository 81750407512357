.cta-button-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .cta-button-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    @include screen-xl-max {
      width: var(--app-wrapper-large);
    }
    .heading {
      margin-bottom: 24px;
      @include screen-sm-max {
        margin-bottom: 18px;
      }
    }
    .body {
      margin-bottom: 24px;
      @include screen-sm-max {
        margin-bottom: 32px;
      }
    }
    .partial-button {
      align-self: center;
      background: var(--primary-colour);
      .text-container {
        .text {
          color: var(--button-text-colour);
        }
        .arrow {
          line,
          polyline {
            stroke: var(--button-text-colour);
          }
        }
      }
    }
  }
}