.tabbed-grid-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .tabbed-grid-container {
    width: var(--app-wrapper-large);
    margin: 0 auto;
    &.three {
      .bottom {
        .nested-tile {
          flex-basis: calc(33.333% - 11px);
          @include screen-lg-max {
            flex-basis: calc(50% - 8px);
          }
          @include screen-md-max {
            flex-basis: 100%;
          }
        }
      }
    }
    &.four {
      .bottom {
        .nested-tile {
          flex-basis: calc(25% - 12px);
          @include screen-lg-max {
            flex-basis: calc(50% - 8px);
          }
          @include screen-md-max {
            flex-basis: 100%;
          }
        }
      }
    }
    .top {
      padding: 80px 100px 0;
      background: var(--primary-colour);
      @include screen-xl-max {
        padding: 80px 80px 0;
      }
      @include screen-md-max {
        padding: 80px 34px 0;
      }
      .heading,
      .subheading {
        color: var(--button-text-colour);
      }
      .subheading {
        margin: 48px 0;
      }
      .tabs-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        .plus {
          .plus-vertical {
            opacity: 1;
            transition: opacity 0.3s;
          }
        }
        @include screen-md-max {
          // height: 67px;
          max-height: 67px;
          overflow: hidden;
          transition: max-height 0.3s ease-in-out;
        }
        &.active {
          max-height: 500px;
          transition: max-height 0.3s ease-in-out;
          .plus {
            .plus-vertical {
              opacity: 0;
            }
          }
        }
      }
      .show-more-tabs {
        display: none;
        @include screen-md-max {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          position: relative;
          padding: 24px;
          .plus {
            width: 18px;
            height: 18px;
          }
        }
      }
      .tabs-container {
        display: flex;
        width: 100%;
        @include screen-md-max {
          flex-wrap: wrap;
        }
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: var(--montserrat);
          padding: 24px 0;
          flex: 1;
          text-align: center;
          cursor: pointer;
          transition: background 0.3s;
          @include screen-md-max {
            max-width: unset;
            flex: unset;
            flex-basis: 50%;
            font-weight: 400;
            padding: unset;
          }
        }
        .tab.active {
          background: var(--deg-white);
          font-weight: 600;
        }
      }
    }
    .bottom {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
      padding: 48px 100px 60px;
      @include screen-xl-max {
        padding: 48px 80px 60px;
      }
      @include screen-md-max {
        padding: 50px 34px 30px;
      }
      .nested-tile {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: calc(25% - 12px);
        align-items: center;
        @include screen-lg-max {
          flex-basis: calc(50% - 8px);
        }
        @include screen-md-max {
          flex-basis: 100%;
        }
        .image-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          // max-height: 82px; // removed fixed height - authoring will need to ensure image heights are consistent if captions are set
          overflow: hidden;
          .image {
            width: 100%;
            height: auto;
          }
        }
        .caption-container {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 100%;
          .caption {
            flex-grow: 1;
            padding: 16px 0;
            text-align: center;
          }
          .coloured-line {
            background: var(--primary-colour);
            width: 100%;
            height: 2px;
          }
        }
      }
    }
  }
}