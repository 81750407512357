:root {
  --app-wrapper-small: 720px;
  --app-wrapper-medium: 960px;
  --app-wrapper-large: 1200px;
  --app-wrapper-full: 100%;
  --app-container-max: 1440px;

  @include screen-xxl-max {
    --app-wrapper-small: 70%;
    --app-wrapper-large: 100%;
    --app-container-max: 90%;
  }
  @include screen-lg-max {
    --app-wrapper-large: 100%;
    --app-wrapper-medium: 100%;
    --app-wrapper-small: 100%;
  }
  @include screen-sm-max {
    --app-container-max: 90%;
  }
}