.media-tiles-eight-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .media-tiles-eight-container {
    position: relative;
    display: flex;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    @include screen-lg-max {
      width: 100%;
    }
    .media-tiles-eight-desktop {
      display: flex;
      width: 100%;
      @include screen-lg-max {
        display: none;
      }
      .tile {
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .column-left {
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tile-1 {
          width: 110%;
          height: 432px;
        }
        .tile-2 {
          align-self: center;
          width: 480px;
          height: 345px;
          margin-bottom: 30%;
        }
      }
      .column-right {
        position: relative;
        margin-top: 50px;
        width: 50%;
        display: flex;
        flex-direction: column;
        .tile-3 {
          align-self: center;
          width: 100%;
          height: 432px;
        }
        .tile-4 {
          align-self: center;
          margin-left: 25%;
          width: 240px;
          height: 173px;
        }
        .tile-5 {
          align-self: center;
          width: 360px;
          height: 259px;
        }
        .tile-6 {
          align-self: center;
          width: 360px;
          height: 432px;
        }
      }
      .column-overlay {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%);
        z-index: 1;
        .tile-7 {
          width: 648px;
          height: 466px;
        }
        .tile-8 {
          align-self: center;
          margin-left: 40%;
          width: 240px;
          height: 173px;
        }
      }
    }
    .media-tiles-eight-mobile {
      display: none;
      background: #f2f2f4;
      @include screen-lg-max {
        display: flex;
        align-items: center;
        width: 100%;
        height: 500px;
      }
      @include screen-md-max {
        height: 400px;
      }
      @include screen-sm-max {
        height: 300px;
      }
      .splide {
        position: relative;
        width: 100%;
        height: 100%;
        .splide__track {
          width: 100%;
          height: 100%;
          .splide-wrapper,
          ul,
          li,
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}