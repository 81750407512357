.content-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .content-container {
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    > * {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: var(--deg-pure-black);
      text-underline-offset: 3px;
      @media (hover: hover) {
        &:hover {
          text-decoration: none;
        }
      }
    }
    h4,
    h5 {
      font-weight: 400;
    }
  }
}