.block.video-hero-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  aspect-ratio: 16 / 9;
  .video-hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    .video {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      display: none;
    }

    .desktop-video {
      display: block;
      @include screen-md-max {
        display: none;
      }
    }

    .mobile-video {
      display: none;
      @include screen-md-max {
        display: block;
      }
    }
    
    .heading-overlay-desktop {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 80%);
      z-index: 5;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      &.scroll-down {
        opacity: 0;
      }
      .heading-overlay-desktop-container {
        position: relative;
        max-width: 90%;
        width: 90%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .heading-overlay-text {
          color: var(--deg-white);
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
}