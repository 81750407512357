.icon-text-row-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .icon-text-row-container {
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    .row {
      display: flex;
      margin-bottom: 100px;
      @include screen-md-max {
        flex-direction: column;
        margin-bottom: 50px;
      }
      &.right-align-image {
        flex-direction: row-reverse;
        @include screen-md-max {
          flex-direction: column;
        }
        .details-container {
          margin-left: 0;
          margin-right: 120px;
          @include screen-xl-max {
            margin-right: 60px;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &:nth-child(2n) {
        flex-direction: row-reverse;
        @include screen-md-max {
          flex-direction: column;
        }
        .details-container {
          margin-left: 0;
          margin-right: 120px;
          @include screen-xl-max {
            margin-right: 60px;
          }
        }
      }
      .media-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 340px;
        @include screen-md-max {
          flex: unset;
          height: 360px;
          margin-bottom: 25px;
        }
        .background-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          z-index: -1;
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon {
            width: 120px;
            max-width: 120px;
            margin-bottom: 16px;
          }
          .small-title,
          .large-title {
            font-weight: 400;
            color: var(--deg-white);
          }
        }
      }
      .details-container {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex: 1;
        margin-left: 120px;
        @include screen-xxl-max {
          margin-left: 60px;
        }
        @include screen-xl-max {
          margin-left: 40px;
        }
        @include screen-md-max {
          margin-left: 0;
        }
        > * {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .cta {
          position: relative;
          display: flex;
          padding: 20px 40px;
          margin-top: 16px;
          border-radius: 8px;
          text-decoration: none;
          background: var(--primary-colour);
          cursor: pointer;
          .text-container {
            position: relative;
            .text {
              font-weight: 600;
              color: var(--button-text-colour);
              transition: transform 0.2s;
              will-change: transform;
            }
            .arrow {
              position: absolute;
              right: 0;
              opacity: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 25px;
              transition: all 0.2s;
              line,
              polyline {
                stroke: var(--button-text-colour);
              }
            }
          }
          @media (hover: hover) {
            &:hover {
              .text {
                transform: translateX(-15px);
              }
              .arrow {
                right: -24px;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}