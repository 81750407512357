.logo-text-listing-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .logo-text-listing-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: var(--app-wrapper-large);
    margin-left: auto;
    margin-right: auto;
    @include screen-xxl-max {
      width: var(--app-wrapper-full);
    }
    .table-container {
      border: 1px solid var(--deg-dark-grey);
      margin: 48px 0 0;
      .row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--deg-dark-grey);
        padding: 30px 100px;
        text-decoration: none;
        transition: color 0.3s ease-in-out;
        @include screen-xl-max {
          padding: 35px 30px;
        }
        &:last-child {
          border-bottom: 0;
        }
        .logo {
          position: relative;
          max-width: 53px;
          width: 53px;
          @include screen-xl-max {
            align-self: flex-start;
          }
        }
        .row-details {
          display: flex;
          align-items: center;
          @include screen-xl-max {
            align-items: flex-start;
            flex-direction: column;
            margin-left: 16px;
          }
          .left {
            display: flex;
            padding-right: 48px;
            @include screen-xl-max {
              flex-basis: 100%;
            }
            .row-title {
              margin-left: 16px;
              margin-right: 16px;
              max-width: 200px;
              width: 200px;
              @include screen-xl-max {
                margin-bottom: 15px;
                margin-left: 0;
                padding-right: 0;
                max-width: unset;
                width: 100%;
              }
            }
          }
          .right {
            display: flex;
            flex-basis: 50%;
            @include screen-xl-max {
              flex-basis: 100%;
              padding: 0;
            }
            .row-body {
              display: flex;
              flex: 1;
              position: relative;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}