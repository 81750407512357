.app-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: #ffffff;
  padding: 32px 52px;
  z-index: 99997;
  overflow: hidden;
  transition: all 0.3s;
  @include screen-lg-max {
    flex-direction: unset;
    padding: 25px 20px;
    transition: background 0.8s cubic-bezier(0.19, 1, 0.22, 1), padding 0.3s ease-in-out;
  }
  &.secondary.scroll-down {
    .container {
      .section-top {
        .logo-container {
          .app-logo.app-logo-primary {
            opacity: 0;
            overflow: hidden;
          }
          .app-logo.app-logo-secondary {
            opacity: 1;
          }
        }
      }
    }
  }
  &.secondary.scroll-down {
    .container {
      .section-top {
        .logo-container {
          .app-logo.app-logo-primary {
            height: 0px;
            opacity: 0;
            overflow: hidden;
          }
          .app-logo.app-logo-secondary {
            opacity: 1;
          }
        }
      }
    }
  }
  &.active {
    @include screen-lg-max {
      height: 100vh;
      background: var(--deg-pure-black);
      border-bottom: 1px solid transparent;
      will-change: height;
      .container {
        flex-direction: column;
        .section-top {
          .logo-container {
            .app-logo {
              .logo {
                position: relative;
              }
            }
          }
        }
        .section-bottom {
          display: flex;
          flex-direction: column;
          height: auto;
        }
      }
    }
  }
  .brand-mark-block {
    display: none;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 75%;
    .brand-mark {
      fill: none;
      stroke-width: 174px;
      stroke-miterlimit: 10;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .section-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .logo-container {
        display: flex;
        position: relative;
        .app-logo {
          display: flex;
          align-items: center;
          .logo {
            opacity: 1;
            &.logo-primary {
              opacity: 1;
              transition: opacity 0.3s;
              overflow: hidden;
            }
          }
        }
        .app-logo-primary {
          opacity: 1;
          transition: all 0.2s ease-in-out;
        }
        .app-logo-secondary {
          position: absolute;
          opacity: 0;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: opacity 0.2s;
        }
      }
      .item {
        position: relative;
        h5 {
          font-weight: 400;
        }
        &:after {
          content: '';
          display: block;
          height: 5px;
          width: 0;
          position: absolute;
          right: 0;
          bottom: 5px;
          z-index: -1;
          background: var(--primary-colour);
          transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
          transition-delay: 0ms;
        }
      }
      @media (hover: hover) {
        .item:hover:after {
          width: 100%;
          left: 0;
        }
      }
      .items-container {
        display: flex;
        align-items: center;
        @include screen-xl-max {
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }
        @include screen-lg-max {
          display: none;
        }
        * {
          margin-left: 24px;
          color: unset;
          text-decoration: none;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
        }
        .item-small h5 {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          @include screen-md-max {
            font-size: 14px;
            line-height: 24px;
          }
        }
        .items-large-container {
          display: flex;
        }
        .items-small-container {
          display: flex;
          margin-top: 5px;
        }
      }
      .icon-hamburger-menu {
        align-self: center;
        width: 24px;
        height: 24px;
        display: none;
        overflow: hidden;
        transform: scale(1);
        transition: all 0.3s;
        @include screen-lg-max {
          display: flex;
        }
        &.mobile {
          transform: scale(1.3);
          line {
            stroke: var(--deg-white);
            transform: rotate(0deg);
          }
          .line-1 {
            transform-origin: 20% 20%;
            transform: rotate(45deg);
          }
          .line-2 {
            opacity: 0;
          }
          .line-3 {
            transform-origin: 20% 80%;
            transform: rotate(-45deg);
          }
        }
      }
      .item.active {
        h5 {
          font-weight: 600;
        }
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
    .section-bottom {
      display: none;
      align-items: flex-start;
      margin-top: 30px;
      height: 0;
      .item {
        color: var(--deg-white);
        text-decoration: none;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.app-header.secondary.scroll-down {
  padding: 30px 52px;
  @include screen-lg-max {
    padding: 25px 20px;
  }
}