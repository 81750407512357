.careers-page-intro-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  > * {
    margin-bottom: 48px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .careers-page-intro-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .intro-container {
      display: flex;
      flex-direction: column;
      width: var(--app-wrapper-medium);
      margin: 0 auto;
      @include screen-xl-max {
      }
      > * {
        margin-bottom: 48px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .small-title {
        font-family: var(--montserrat);
        font-size: 12px;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
    .hero-image-container {
      position: relative;
      margin: 48px auto;
      max-height: 600px;
      width: var(--app-wrapper-large);
      height: 600px;
      overflow: hidden;
      @include screen-xl-max {
        max-height: 550px;
        height: 550px;
      }
      @include screen-sm-max {
        max-height: 250px;
        height: 250px;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}