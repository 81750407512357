.locations-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .container {
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .heading {
      margin-bottom: 75px;
      @include screen-lg-max {
        margin-bottom: 60px;
      }
    }
    .location-tiles-container {
      display: flex;
      flex-wrap: wrap;
      &.one {
        .tile {
          flex-basis: 100%;
          margin-right: 0;
          margin-bottom: 60px;
        }
      }
      .tile {
        display: flex;
        flex-direction: column;
        flex-basis: calc(50% - 30px);
        margin-right: 60px;
        filter: var(--tile-drop-shadow);
        &:last-child {
          @include screen-lg-max {
            margin-bottom: 0;
          }
        }
        @include screen-lg-max {
          flex-basis: 100%;
          margin-right: 0;
          margin-bottom: 60px;
        }
        .image-container {
          position: relative;
          height: 230px;
          .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .details-container {
          background: var(--deg-white);
          padding: 30px;
          .details {
            margin-top: 30px;
            .detail-block {
              display: flex;
              align-items: center;
              margin-bottom: 30px;
              &:last-child {
                margin-bottom: 0;
              }
              a {
                text-decoration: none;
                color: var(--primary-colour);
              }
              .icon {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 30px;
                width: 30px;
                max-height: 30px;
                height: 30px;
                margin-right: 16px;
                img,
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
      .tile:nth-child(2n) {
        margin-right: 0;
        margin-top: 60px;
        @include screen-lg-max {
          margin-top: 0;
        }
      }
    }
  }
}