.tabbed-grid-block {
  .tabbed-grid-container {
    .top {
      .tabs-container {
        .tab {
          font-weight: 600;
        }
        .tab.active {
          font-weight: 600;
        }
      }
    }
  }
}