.awards-block {
  display: flex;
  align-items: center;
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .awards-block-container {
    display: flex;
    justify-content: space-between;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    @include screen-xl-max {
      flex-wrap: wrap;
    }
    .award {
      position: relative;
      max-width: 240px;
      @include screen-xl-max {
        flex-basis: calc(50% - 16px);
        margin-bottom: 60px;
        max-width: unset;
      }
      &:nth-child(2n+0) {
        margin-top: 150px;
        @include screen-xl-max {
          margin-top: 0;
        }
      }
      @media (hover: hover) {
        &:hover {
          .award-details {
            .heading,
            .subheading {
              color: var(--link-hover-colour);
            }
          }
        }
      }
      a {
        text-decoration: none;
        color: var(--deg-pure-black);
        transition: color 0.3s ease-in-out;
      }
      .image-wrapper {
        display: flex;
        position: relative;
        margin-bottom: 1rem;
        max-width: 240px;
        width: 240px;
        .image {
          position: relative;
          width: auto;
        }
      }
      .heading {
        max-width: 280px;
        transition: color 0.3s ease-in-out;
      }
      .subheading {
        font-weight: 400;
        transition: color 0.3s ease-in-out;
      }
    }
  }
}