$carouselHeight: 250px;
$carouselMobileSplit: 2.6;

.team-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  font-family: var(--montserrat);
  .close {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
  .team-container {
    position: relative;
    display: flex;
    width: 100%;
    margin: 0 auto;
    @include screen-xl-max {
      flex-wrap: wrap;
    }
    .modal-container {
      position: absolute;
      top: -5%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      opacity: 0;
      visibility: hidden;
      transition: top 0.3s ease-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      &.active {
        top: 0%;
        opacity: 1;
        visibility: visible;
      }
      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        .modal {
          display: flex;
          position: absolute;
          top: 50%;
          left: 50%;
          width: $screen-md-max;
          height: 400px;
          background: var(--deg-white);
          border: 1px solid var(--deg-pure-black);
          transform: translate(-50%, -50%);
          z-index: 5;
          @include screen-md-max {
            width: 80%;
            flex-direction: column;
            max-height: 650px;
            height: 650px;
          }
          @include screen-sm-max {
            width: 100%;
            max-height: 550px;
            height: 550px;
          }
          .modal-profile {
            position: relative;
            flex: 1;
            @include screen-md-max {
              flex: 1.5;
            }
            .close-mobile {
              position: absolute;
              display: none;
              top: 1.5rem;
              right: 1.5rem;
              z-index: 5;
              @include screen-md-max {
                display: block;
              }
            }
            .image {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              object-fit: cover;
            }
          }
          .modal-details {
            position: relative;
            flex: 1.5;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 3rem;
            @include screen-md-max {
              flex: 1;
              padding: 2.5rem 1.5rem 1.5rem;
            }
            &:before {
              position: absolute;
              content: '';
              width: 40px;
              height: 1px;
              left: -20px;
              top: 50%;
              background: #b3b3b3;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              @include screen-md-max {
                width: 1px;
                height: 40px;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                -webkit-transform: translateX(-50%);
              }
            }
            .details-container {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              .member-name,
              .role {
                font-weight: 400;
                color: #797979; // to be updated
              }
              .close-desktop {
                position: relative;
                align-self: flex-end;
                @include screen-md-max {
                  display: none;
                }
              }
              .middle {
                display: flex;
                flex-direction: column;
                .quote {
                  margin-bottom: 16px;
                }
                .details-desktop {
                  @include screen-md-max {
                    display: none;
                  }
                }
              }
              .bottom {
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
                @include screen-md-max {
                  justify-content: space-between;
                }
                .social {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  align-self: flex-end;
                  background: var(--deg-white);
                  padding: 0.5rem;
                  border: 1px solid var(--deg-pure-black);
                  cursor: pointer;
                  transition: all 0.3s;
                  @media (hover: hover) {
                    &:hover {
                      background: var(--deg-pure-black);
                      .icon-linkedin path {
                        fill: var(--deg-white);
                      }
                    }
                  }
                  .icon-linkedin {
                    width: 20px;
                    height: 20px;
                  }
                }
                .social.active {
                  background: var(--deg-pure-black);
                  .icon-linkedin path {
                    fill: var(--deg-white);
                  }
                }
                .details-mobile {
                  display: none;
                  @include screen-md-max {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    .text-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 0;
      .inner {
        position: relative;
        width: 100%;
        height: 100%;
        .text-top,
        .text-bottom {
          position: absolute;
          font-weight: 600;
          font-size: 250px;
          opacity: 0.1;
          @include screen-xl-max {
            font-size: 170px;
          }
          @include screen-md-max {
            display: none;
          }
        }
        .text-top {
          top: 0;
          left: 0;
        }
        .text-bottom {
          bottom: 0;
          right: 0;
        }
      }
    }
    .carousel {
      position: relative;
      flex-basis: 100%;
      height: $carouselHeight;
      overflow: hidden;
      cursor: pointer;
      @include screen-xxl-max {
        height: calc($carouselHeight - 80px);
      }
      @include screen-xl-max {
        height: calc($carouselHeight + 80px);
        flex-basis: 33.33%;
      }
      @include screen-lg-max {
        height: calc($carouselHeight + 20px);
      }
      @include screen-md-max {
        height: calc($carouselHeight - 30px);
      }
      @include screen-sm-max {
        height: calc($carouselHeight - 100px);
      }
      .splide {
        position: relative;
        width: 100%;
        height: 100%;
        .splide__track {
          width: 100%;
          height: 100%;
          .splide-wrapper,
          ul,
          li,
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .splide__list {
            img {
              transition: 0.5s all;
              flex-basis: 100%;
              @media (hover: hover) {
                &:hover {
                  transform: scale(1.2);
                }
              }
            }
          }
        }
      }
    }
    .carousel-1,
    .carousel-3,
    .carousel-5 {
      margin-top: calc($carouselHeight / 2)
    }
    .carousel-2,
    .carousel-6 {
      margin-top: calc($carouselHeight);
    }
    @include screen-xl-max {
      .carousel {
        margin-top: 0;
      }
      .carousel-1 { // first
        margin-top: calc($carouselHeight / $carouselMobileSplit);
      }
      .carousel-4 { // fourth
        margin-top: calc($carouselHeight / calc($carouselMobileSplit * -1));
      }
      .carousel-2 { // second
        margin-top: calc($carouselHeight / calc($carouselMobileSplit / 2));
      }
      .carousel-6 { // sixth
        margin-top: calc($carouselHeight / calc($carouselMobileSplit / -2));
      }
    }
  }
}