.history-block {
  .history-container {
    .text-wrapper {
      .text-container {
        .tag.small,
        .body {
          color: var(--deg-pure-black);
        }
        .title {
          color: var(--link-hover-colour);
        }
      }
    }
  }
}