.quote-block {
  .quote-container {
    .details-container {
      .body {
        color: var(--primary-colour);
      }
      .small {
        color: var(--deg-pure-black);
      }
    }
  }
}