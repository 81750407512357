.listing-table-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  .listing-table-container {
    display: flex;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    &.two {
      .table-container {
        .item {
          flex-basis: calc(50% - 24px);
          @include screen-md-max {
            flex-basis: 100%;
          }
        }
      }
    }
    &.three {
      .table-container {
        .item {
          flex-basis: calc(33.333% - 32px);
          @include screen-lg-max {
            flex-basis: calc(50% - 32px);
          }
          @include screen-md-max {
            flex-basis: 100%;
          }
        }
      }
    }
    &.four {
      .table-container {
        .item {
          flex-basis: calc(25% - 36px);
          @include screen-lg-max {
            flex-basis: calc(50% - 24px);
          }
          @include screen-md-max {
            flex-basis: 100%;
          }
        }
      }
    }
    .subheading {
      margin: 48px 0;
      font-weight: 400;
      @include screen-md-max {
        margin: 24px 0;
      }
    }
    .table-container {
      display: flex;
      flex-wrap: wrap;
      gap: 0 48px;
      .item {
        display: flex;
        align-items: center;
        padding: 18px 0;
        flex-basis: calc(33.333% - 32px);
        color: var(--deg-mid-grey);
        border-bottom: 1px solid var(--deg-pure-black);
        @include screen-lg-max {
          flex-basis: calc(50% - 32px);
        }
        @include screen-md-max {
          flex-basis: 100%;
        }
      }
      @include screen-md-max {
        .item:nth-child(n + 6) {
          display: none;
          &.visible {
            display: flex;
          }
        }
      }
    }
    .view-more {
      display: none;
      margin-top: 24px;
      justify-content: center;
      @include screen-md-max {
        display: flex;
      }
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .arrow-down {
          width: 15px;
          height: 29px;
          cursor: pointer;
        }
        .text {
          color: var(--deg-mid-grey);
          margin-top: 8px;
          cursor: pointer;
        }
      }
    }
  }
}