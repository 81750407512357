.overlapping-double-media-text-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .overlapping-double-media-text-container {
    display: flex;
    flex-direction: column;
    position: relative;
    width: var(--app-wrapper-large);
    @include screen-xxl-max {
      width: var(--app-wrapper-full);
    }
    .section {
      display: flex;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      &:nth-child(2n) {
        margin-top: -250px;
      }
      &:nth-child(3n) {
        margin-top: -50px;
      }
      @include screen-xl-max {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 48px;
      }
    }
    .image-media-container {
      position: relative;
      width: 650px;
      height: 650px;
      @include screen-xl-max {
        width: 65%;
        height: auto;
      }
    }
    .media-image,
    .media-video {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: var(--tile-drop-shadow);
    }
    .text-container {
      display: flex;
      width: 45%;
      // margin-top: -300px;
      margin-top: -300px;
      @include screen-xl-max {
        width: 100%;
        margin-top: 32px;
      }
      .divider {
        width: 32px;
        height: 1px;
        background: var(--primary-colour);
        margin: 14px 24px 0;
        @include screen-xl-max {
          display: none;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        flex: 1;
        .heading {
          font-weight: 600;
          margin-bottom: 16px;
        }
        .body {
          font-weight: 400;
          color: #797979;
        }
      }
    }
    .section:nth-child(2n) {
      flex-direction: row-reverse;
      @include screen-xl-max {
        flex-direction: column;
        align-items: flex-end;
        margin-top: 32px;
      }
      .text-container {
        flex-direction: row-reverse;
        text-align: right;
        margin-top: 200px;
        @include screen-xl-max {
          flex-direction: row;
          text-align: right;
          margin-top: 32px;
        }
      }
    }
    .section:nth-child(3n) {
      @include screen-xl-max {
        margin-top: 32px;
      }
      .text-container {
        margin-top: -150px;
        @include screen-xl-max {
          margin-top: 32px;
        }
      }
    }
  }
}