.app-footer {
  position: relative;
  padding: 35px;
  font-size: 12px;
  line-height: 20px;
  color: var(--deg-mid-grey);
  overflow: hidden;
  @include screen-md-max {
    padding: 20px;
  }
  a {
    color: var(--deg-mid-grey);
    @media (hover: hover) {
      &:hover {
        color: var(--deg-white);
      }
    }
  }
  .container {
    width: var(--app-container-max);
    margin: 0 auto;
    @include screen-xxl-max {
      width: 100%;
    }
    .section-top {
      border: 1px solid var(--deg-neutral-grey-2);
    }
    .section-bottom {
      margin-top: 2rem;
    }
    .row {
      display: flex;
      position: relative;
    }
    .row-1 {
      @include screen-lg-max {
        flex-direction: column;
      }
      .logo-tagline-container {
        display: flex;
        flex: 1;
        @include screen-lg-max {
          border-bottom: 1px solid var(--deg-neutral-grey-2);
        }
        .logo-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 85px;
          img {
            position: relative;
            width: 19px;
            height: 33px;
          }
        }
        .tagline-container {
          display: flex;
          align-items: center;
          border-left: 1px solid var(--deg-neutral-grey-2);
          padding: 1.5rem;
          flex: 1;
          .tagline {
            color: var(--deg-white);
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .social-container {
        position: relative;
        display: flex;
        align-items: center;
        @include screen-lg-max {
          justify-content: flex-end;
        }
        .social-container-copy {
          padding-right: 1.5rem;
        }
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.5rem;
          border-left: 1px solid var(--deg-neutral-grey-2);
          svg {
            path {
              transition: fill 0.3s;
            }
          }
          @media (hover: hover) {
            &:hover {
              svg {
                path {
                  fill: var(--deg-white);
                }
              }
            }
          }
        }
        .icon {
          width: 35px;
          height: 35px;
        }
      }
    }
    .row-2 {
      border-top: 1px solid var(--deg-neutral-grey-2);
      .city-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        pointer-events: none;
        .accordion-icon-toggle {
          position: relative;
          width: 22px;
          height: 22px;
          display: none;
          @include screen-lg-max {
            display: block;
          }
          .accordion-icon {
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(0deg);
            transform-origin: 50% 50%;
            transition: transform 0.2s ease-in-out;
          }
          .icon-plus {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
          }
        }
      }
      .accordion-wrapper {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: max-height 500ms cubic-bezier(0.5, 1, 0.22, 1);
        @include screen-lg-max {
          max-height: 0;
        }
      }
    }
    .row-3 {
      border-top: 1px solid var(--deg-neutral-grey-2);
      @include screen-lg-max {
        flex-direction: column;
      }
      .row-3-container {
        display: flex;
        justify-content: space-between;
        padding-left: 110px;
        width: 100%;
        @include screen-lg-max {
          flex-direction: column;
          padding-left: 0;
        }
        .row-3-section {
          display: flex;
          @include screen-lg-max {
            padding-left: 35px;
            padding-right: 35px;
          }
          .row-3-section-block {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 24px;
            border-left: 1px solid var(--deg-neutral-grey-2);
            @include screen-lg-max {
              flex: 1;
              &:first-child {
                border-left: 0;   
              }
            }
            img {
              width: auto;
              height: 50px;
            }
          }
        }
        .row-3-section.certifications {
          @include screen-lg-max {
            border-top: 1px solid var(--deg-neutral-grey-2);
            justify-content: space-between;
            padding-left: 0;
            padding-right: 0;
          }
        }
        .group-tagline {
          display: flex;
          align-items: center;
          margin-right: 10px;
          * {
            font-size: 12px;
            line-height: 20px;
          }
          a {
            text-decoration: none;
            &:hover {
              text-decoration: underline;
              text-underline-offset: 4px;
            }
          }
        }
        .group-brands {
          display: flex;
          align-items: center;
          padding: 32px 0;
          @include screen-md-max {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
          }
          .brand {
            display: inline-block;
            color: var(--deg-white);
            text-decoration: underline;
            text-underline-offset: 4px;
            margin-right: 10px;
            &:last-child {
              margin-right: 0;
            }
            @media (hover: hover) {
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    .row-2-container {
      justify-content: space-between;
      align-items: center;
      @include screen-lg-max {
        align-items: unset;
      }
      > * {
        border-right: 1px solid var(--deg-neutral-grey-2);
        &:last-child {
          border-right: 0;
        }
      }
      &.multiple {
        flex-direction: column;
        width: 100%;
        align-items: unset;
        > * {
          border-right: 0;
        }
        .row-2-locations {
          display: flex;
          @include screen-lg-max {
            flex-direction: column;
            padding: 0;
            border-bottom: 0;
          }
          .location {
            flex: 1;
            @include screen-lg-max {
              padding: 35px;
            }
          }
        }
        .row-2-acknowledgement {
          border-left: 0;
          border-top: 1px solid var(--deg-neutral-grey-2);
          padding-top: 35px;
          padding-bottom: 35px;
          @include screen-md-max {
            padding: 35px;
          }
        }
      }
      @include screen-lg-max {
        flex-direction: column;
      }
      .row-2-locations {
        flex: 1;
        padding-left: 110px;
        padding-right: 110px;
        @include screen-lg-max {
          padding-left: 35px;
          padding-right: 35px;
        }
      }
      .row-2-acknowledgement {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 110px;
        padding-right: 110px;
        @include screen-lg-max {
          padding: 35px;
          border-left: 0;
          border-top: 1px solid var(--deg-neutral-grey-2);
        }
      }
    }
    .row-2-container,
    .row-3-container,
    .section-bottom-container {
      display: flex;
      @include screen-lg-max {
        width: 100%;
        margin: unset;
      }
    }
    .section-bottom-container {
      padding-left: 110px;
      padding-right: 110px;
      flex-direction: column;
      align-items: flex-start;
      @include screen-lg-max {
        padding-left: 35px;
        padding-right: 35px;
      }
      a[href^="tel"] {
        color: inherit;
        text-decoration: none;
      }
      .copyright {
        text-decoration: none;
        pointer-events: none;
        color: var(--deg-mid-grey);
      }
      .privacy {
        margin-top: 4px;
      }
    }
    .location {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 35px 0;
      cursor: pointer;
      @include screen-lg-max {
        border-bottom: 1px solid var(--deg-neutral-grey-2);
        padding: 35px 0;
        &:last-child {
          border-bottom: 0;
        }
      }
      .phone,
      .email {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
      .email {
        margin-top: 2rem;
      }
      .city {
        font-weight: 600;
        color: var(--deg-white);
      }
    }
    .location.active {
      .accordion-wrapper {
        max-height: 300px;
      }
      .city-wrapper {
        .accordion-icon {
          transform: rotate(180deg);
        }
        .icon-plus {
          opacity: 0;
        }
      }
    }
  }
}