.event-details-block {
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .event-details-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    width: var(--app-wrapper-medium);
    background: var(--background-colour);
    padding: 36px;
    margin: 0 auto;
    @include screen-xl-max {
      width: var(--app-wrapper-large);
    }
    .heading {
      margin-bottom: 28px;
    }
    .icons-wrapper {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 28px;
      .item {
        display: flex;
        align-items: center;
        margin-right: 16px;
        padding: 5px 0;
        &:last-child {
          margin-right: 0;
        }
        .icon {
          max-width: 24px;
          max-height: 24px;
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }
    }
    .partial-button {
      align-self: center;
    }
  }
}