.social-share-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .social-share-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    .label {
      color: var(--deg-neutral-grey-3);
      margin-right: 16px;
      font-weight: 600;
    }
    .icon-wrapper {
      display: flex;
      border: 1px solid var(--deg-pure-black);
      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid var(--deg-pure-black);
        padding: 10px;
        &:last-child {
          border: 0;
        }
        .icon {
          max-width: 50px;
          width: 50px;
        }
      }
    }
  }
}