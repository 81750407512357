.home-intro-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--app-container-max);
  height: 85vh;
  margin: 0 auto;
  overflow: hidden;
  // background-image
  &.enter {
    .background-image-container {
      .background-image {
        transform: scale(1);
      }
    }
  }
  // top-mask / bottom-mask
  &.enter-top-mask {
    .home-intro-container {
      .heading-container-top:after {
        width: 100%;
        left: 0;
      }
    }
  }
  &.enter-top-mask.exit-top-mask {
    .home-intro-container {
      .heading-container-top {
        .heading {
          opacity: 1;
        }
      }
      .heading-container-top:after {
        width: 0;
        left: unset;
        right: 0;
      }
      .heading-container-bottom:after {
        width: 100%;
        left: 0;
      }
    }
  }
  &.enter-top-mask.exit-top-mask.exit-bottom-mask {
    .home-intro-container {
      .heading-container-bottom {
        .heading {
          opacity: 1;
        }
      }
      .heading-container-bottom:after {
        width: 0;
        left: unset;
        right: 0;
      }
      .body {
        > * {
          opacity: 1;
          transform: translateY(0px);
        }
      }
      .expertise-icons {
        > * {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
  .background-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    .background-image {
      position: relative;
      width: 420px;
      height: 95%;
      z-index: -1;
      transform-origin: '50% 50%';
      transform: scale(0);
      transition: transform 0.8s cubic-bezier(0, 1.4, 1, 1);
      @include screen-md-max {
        width: 290px;
      }
    }
  }
  .home-intro-container {
    position: absolute;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: var(--app-wrapper-small);
    .heading-container {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 800px;
      margin-bottom: 20px;
      @include screen-md-max {
        margin-bottom: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .heading {
        opacity: 0;
        display: block;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        background: var(--primary-colour);
        width: 0;
        height: 102%;
        top: 0;
        right: 0;
        transition: width 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition-delay: 0ms;
        z-index: 10;
      }
    }
    .body {
      margin-top: 48px;
      @include screen-md-max {
        margin-top: 24px;
      }
      > * {
        font-weight: 400;
        margin-bottom: 16px;
        opacity: 0;
        transform: translateY(30px);
        transition: transform 0.8s ease-out, opacity 0.8s ease-in-out;
        &:last-child {
          margin-bottom: 0;
        }
        @for $i from 0 through 10 {
          &:nth-child(#{$i + 1}) {
            transition-delay: 0.1s * $i;
          }
        }
      }
    }
    .expertise-icons {
      display: flex;
      margin-top: 48px;
      @include screen-lg-max {
        width: 100%;
      }
      @include screen-md-max {
        margin-top: 24px;
      }
      @include screen-sm-max {
        justify-content: space-between;
      }
      .icon-expertise {
        text-decoration: none;
        color: var(--deg-pure-black);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 32px;
        cursor: pointer;
        opacity: 0;
        transform: translateY(50px);
        transition: transform 0.8s ease-out, opacity 0.8s ease-in-out;
        @media (hover: hover) {
          &:hover {
            .icon-container {
              img,
              svg {
                path {
                  stroke: var(--link-hover-colour);
                }
              }
            }
            .label {
              color: var(--link-hover-colour);
            }
          }
        }
        @for $i from 0 through 10 {
          &:nth-child(#{$i + 1}) {
            transition-delay: 0.1s * $i;
          }
        }
        @include screen-lg-max {
          flex-direction: column;
          flex-basis: 33.333%;
          margin-right: 0;
        }
        @include screen-sm-max {
          flex-basis: unset;
        }
        &:last-child {
          margin-right: 0;
        }
        &.active {
          .icon-container {
            svg {
              path {
                stroke: var(--primary-colour);
              }
            }
          }
          .label {
            transition: color 0.3s;
            color: var(--primary-colour);
          }
        }
        .icon-container {
          display: flex;
          align-items: center;
          @include screen-lg-max {
            justify-content: center;
          }
          @include screen-md-max {
            flex-direction: column;
          }
          img,
          svg {
            width: 80px;
            margin-right: 16px;
            @include screen-lg-max {
              margin-right: 0;
            }
          }
        }
        .label {
          font-weight: 400;
          transition: color 0.3s;
          @include screen-lg-max {
            margin-top: 16px;
          }
        }
      }
    }
  }
}