.project-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .project-container {
    display: flex;
    position: relative;
    width: var(--app-wrapper-large);
    margin: 0 auto;
    @include screen-lg-max {
      height: unset;
      flex-direction: column-reverse;
    }
    .media-1,
    .media-2,
    .media-3 {
      position: absolute;
      .image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      .image-video {
        position: relative;
        width: 100%;
        height: 100%;
        filter: var(--tile-drop-shadow);
        .image-video-container {
          position: relative;
          width: 100%;
          height: 100%;
          .media-image,
          .media-video {
            position: relative;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
          }
        }
      }
    }
    .media-container-1 {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 50%;
      z-index: 1;
      @include screen-lg-max {
        width: 100%;
        height: unset;
        position: relative;
      }
      .wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 17%;
        align-items: flex-start;
        width: 100%;
        @include screen-lg-max {
          margin-top: 0;
        }
        .media-1 {
          position: relative;
          width: 120%;
          z-index: 3;
          background: #dbf4f0;
          @include screen-lg-max {
            display: none; // based on design, only media-2 and media-3 should be displayed on mobile devices
          }
          img,
          video {
            aspect-ratio: 3 / 2;
          }
        }
        .text-container {
          position: relative;
          align-self: center;
          width: 360px;
          margin-top: 17%;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          @include screen-lg-max {
            width: 100%;
            margin-top: 7%;
            margin-right: 0;
          }
          @media (hover: hover) {
            &:hover {
              color: var(--link-hover-colour);
            }
          }
          .tag.small {
            margin-bottom: 20px;
          }
        }
      }
    }
    .media-container-2 {
      position: relative;
      width: 50%;
      z-index: 0;
      left: unset;
      right: 0;
      @include screen-lg-max {
        position: relative;
        width: 100%;
        height: unset;
      }
      .wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        flex-direction: column;
        width: 100%;
        @include screen-lg-max {
          height: unset;
        }
        .media-2 {
          position: relative;
          width: 100%;
          background: #f5e9f9;
          z-index: 1;
          @include screen-lg-max {
            position: relative;
            align-self: flex-end;
            transform: unset;
            width: 90%;
          }
          @include screen-sm-max {
            width: 90%;
          }
        }
        .media-3 {
          position: relative;
          margin-top: -20%;
          align-self: center;
          width: 80%;
          background: #ebf8f7;
          z-index: 2;
          @include screen-lg-max {
            margin-top: -10%;
            width: 90%;
            align-self: flex-start;
          }
        }
      }
    }
  }
}

.project-block.right-column-text {
  .project-container {
    flex-direction: row-reverse;
    @include screen-lg-max {
      flex-direction: column-reverse;
    }
    .wrapper {
      @include screen-lg-max {
        flex-direction: column-reverse;
      }
      .text-container {
        width: 480px;
        @include screen-lg-max {
          width: 100%;
        }
      }
    }
    .media-container-1 {
      .wrapper {
        align-items: flex-end;
        @include screen-lg-max {
          .text-container {
            margin-top: 7%;
          }
        }
      }
    }
    .media-container-2 {
      .wrapper {
        align-items: flex-start;
        .media-2 {
          @include screen-lg-max {
            width: 90%;
            margin-top: -90px;
            z-index: 3;
          }
        }
        .media-3 {
          @include screen-lg-max {
            width: 90%;
            margin-top: unset;
            align-self: flex-start;
          }
        }
      }
    }
  }
}