@mixin screen-sm-max {
  @media (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin screen-md-max {
  @media (max-width: $screen-md-max) {
    @content;
  }
}

@mixin screen-lg-max {
  @media (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin screen-xl-max {
  @media (max-width: $screen-xl-max) {
    @content;
  }
}

@mixin screen-xxl-max {
  @media (max-width: $screen-xxl-max) {
    @content;
  }
}

@mixin screen-xxxl-max {
  @media (max-width: $screen-xxxl-max) {
    @content;
  }
}

@mixin screen-full-max {
  @media (max-width: $screen-full-max) {
    @content;
  }
}
