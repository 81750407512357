.quote-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .quote-container {
    display: flex;
    width: var(--app-wrapper-medium);
    margin: 0 auto;
    > * {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .line-container {
      width: 72px;
      margin-right: 48px;
      margin-top: 24px;
      @include screen-sm-max {
        width: 21px;
        margin-right: 24px;
        margin-top: 16px;
      }
      .line {
        width: 100%;
        height: 1px;
        background: var(--primary-colour);
      }
    }
    .details-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      > * {
        margin-bottom: 48px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}