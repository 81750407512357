.horizontal-icon-tile-block {
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  @include screen-lg-max {
      width: 100%;
      // margin-right: unset;
    }
  .horizontal-icon-tile-container {
    display: flex;
    margin: 0 auto;
    width: var(--app-wrapper-large);
    .desktop-tiles {
      position: relative;
      display: flex;
      gap: 40px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      @include screen-lg-max {
        width: 90%;
        flex-direction: column;
      }
      .tile {
        display: flex;
        flex-direction: column;
        flex-basis: calc(25% - 10px);
        min-height: 270px;
        height: 100%;
        box-shadow: 0px 0px 80px 0px rgba(14, 16, 38, 0.20);
        filter: var(--tile-drop-shadow);
        @include screen-lg-max {
          flex-basis: calc(50% - 20px);
        }
        @include screen-md-max {
          flex-basis: 100%;
        }
        .top {
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 154px;
          height: 154px;
          @include screen-sm-max {
            max-height: 115px;
            height: 115px;
          }
        }
        .top {
          padding: 32px;
        }
        .bottom {
          padding: 0 32px 32px;
        }
      }
    }
    .mobile-tiles {
      display: none;
      position: relative;
      width: 100%;
      overflow: hidden;
      @include screen-lg-max {
        display: block;
        .swiper {
          position: relative;
          width: 100%;
          max-height: 360px;
          height: 360px;
          .swiper-slide {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .tile {
      display: flex;
      flex-direction: column;
      .top {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        min-height: 109px;
        padding: 15px;
        .heading {
          z-index: 2;
        }
      }
      .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        padding: 15px 29px;
        .bottom-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          .icon {
            width: 82px;
          }
          .body {
            font-weight: 400;
          }
        }
      }
    }
  }
}