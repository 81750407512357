* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

svg:not(:root) {
  overflow: visible;
}

input:focus {
  outline: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

html,
body {
  min-height: 100vh;
}

::-webkit-input-placeholder {
  color: var(--deg-pure-black);
}

:-moz-placeholder {
 color: var(--deg-pure-black);
 opacity: 1;
}

::-moz-placeholder {
 color: var(--deg-pure-black);
 opacity: 1;
}

:-ms-input-placeholder {
 color: var(--deg-pure-black);
}

::-ms-input-placeholder {
 color: var(--deg-pure-black);
}

::placeholder {
  color: var(--deg-pure-black);
}