.contact-individual-block {
  display: flex;
  position: relative;
  width: var(--app-container-max);
  margin-left: auto;
  margin-right: auto;
  .contact-individual-container {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    width: var(--app-wrapper-medium);
    margin-left: auto;
    margin-right: auto;
    @include screen-lg-max {
      gap: 48px;
    }
    .contact-individual-item {
      display: flex;
      align-items: center;
      width: 100%;
      @include screen-sm-max {
        align-items: flex-start;
      }
      .image-container {
        position: relative;
        min-width: 150px;
        min-height: 150px;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden;
        @include screen-sm-max {
          min-width: 80px;
          min-height: 80px;
          width: 80px;
          height: 80px;  
        }
        .image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
      .details-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 50%;
        width: 50%;
        margin-left: 48px;
        overflow: hidden;
        text-overflow: ellipsis;
        @include screen-lg-max {
          max-width: 100%;
          width: 100%;
        }
        > * {
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .name {
          font-weight: 600;
        }
        .details-wrapper {
          display: flex;
          align-items: center;
          width: 100%;
          .email,
          .mobile {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-decoration: none;
            font-family: var(--montserrat);
            color: var(--primary-colour);
          }
          span.abbreviation {
            text-decoration: none;
            color: var(--deg-pure-black);
          }
        }
      }
    }
  }
}